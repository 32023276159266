import htmx from "htmx.org";
window.htmx = htmx;

// This function is meant to be used as a callback for idiomorph to ignore updates to some attributes.
// Here we ignore updates to the textarea style prop, as this one is updated by the browser on resize.
// Without this, the textarea resets its size when we get an update from htmx.
window.idiomorphBeforeAttributeUpdated = (
  attributeName,
  node,
  _mutationType,
) => {
  if (node.tagName.toLowerCase() === "textarea" && attributeName === "style") {
    return false;
  }
};
