import jsHelper from "./js-helper";

export const reportHelper = {
  toggleInformation: (id) => {
    var elementId = "#" + id;
    jsHelper.toggle(elementId);
  },

  showItems: (index, id_prependix) => {
    var elementId = "#" + "items-" + id_prependix + "-" + index;
    reportHelper.showElement(elementId);
    if (index > 0) {
      jsHelper.removeClass(".label-" + id_prependix + "-" + index, "hidden");
      jsHelper.addClass(".label-" + id_prependix + "-" + index, "flex");
    }
  },

  hideItems: (index, id_prependix) => {
    var elementId = "#" + "items-" + id_prependix + "-" + index;
    reportHelper.hideElement(elementId);
    if (index > 0) {
      jsHelper.removeClass(".label-" + id_prependix + "-" + index, "flex");
      jsHelper.addClass(".label-" + id_prependix + "-" + index, "hidden");
    }
  },

  toggleFacet: (id) => {
    var elementId = "#facet-" + id;
    if (jsHelper.isHidden(elementId)) {
      reportHelper.showFacet(id);
    } else {
      reportHelper.hideFacet(id);
    }
  },

  showFacet: (id) => {
    var elementId = "#facet-" + id;
    reportHelper.showElement(elementId);
    jsHelper
      .selectFirst(".facet-button")
      .dataset.inactiveClasses.split(" ")
      .forEach((className) => {
        jsHelper.removeClass(elementId + "-button", className);
      });
    jsHelper
      .selectFirst(".facet-button")
      .dataset.activeClasses.split(" ")
      .forEach((className) => {
        jsHelper.addClass(elementId + "-button", className);
      });
  },

  hideFacet: (id) => {
    var elementId = "#facet-" + id;
    reportHelper.hideElement(elementId);
    jsHelper
      .selectFirst(".facet-button")
      .dataset.activeClasses.split(" ")
      .forEach((className) => {
        jsHelper.removeClass(elementId + "-button", className);
      });
    jsHelper
      .selectFirst(".facet-button")
      .dataset.inactiveClasses.split(" ")
      .forEach((className) => {
        jsHelper.addClass(elementId + "-button", className);
      });
  },

  showElement: (elementId) => {
    jsHelper.show(elementId);
    jsHelper.show(elementId + "-hide");
    jsHelper.hide(elementId + "-show");
  },

  hideElement: (elementId) => {
    jsHelper.hide(elementId);
    jsHelper.hide(elementId + "-hide");
    jsHelper.show(elementId + "-show");
  },
};
