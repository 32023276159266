export const jsHelper = {
  selectAll: (selector) => {
    return document.querySelectorAll(selector);
  },

  selectFirst: (selector) => {
    return document.querySelector(selector);
  },

  hide: (selector) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => {
        element.style.setProperty("display", "none");
      });
    }
  },

  show: (selector, type) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => {
        element.style.setProperty("display", type || "block");
      });
    }
  },

  isHidden: (selector) => {
    const elem = document.querySelector(selector);
    return window.getComputedStyle(elem).display === "none";
  },

  toggle: (selector, type) => {
    if (jsHelper.isHidden(selector)) {
      jsHelper.show(selector, type);
    } else {
      jsHelper.hide(selector);
    }
  },

  addClass: (selector, klass) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => element.classList.add(klass));
    }
  },

  removeClass: (selector, klass) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => element.classList.remove(klass));
    }
  },

  addListener: (selector, eventType, handler) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) =>
        element.addEventListener(eventType, handler),
      );
    }
  },

  clearAllEvents: (element) => {
    var parent = element.parentNode;
    if (parent) {
      var clone = element.cloneNode(true);
      parent.replaceChild(clone, element);
    }
  },
};

export default jsHelper;
