import Rails from "@rails/ujs";
import TomSelect from "tom-select";
import { appsignal } from "../shared/appsignal";
import "chartkick/chart.js";
import { reportHelper } from "../js/reports.js";
import jsHelper from "../js/js-helper.js";
import ahoy from "ahoy.js";
// https://github.com/bigskysoftware/htmx/issues/1690
import "../js/htmx.js";
import "idiomorph/dist/idiomorph-ext.js";

// Comment out for debug information
// htmx.logAll();

if (!window._rails_loaded) {
  Rails.start();
}
window.reportHelper = reportHelper;
window.jsHelper = jsHelper;

ahoy.trackView();

document.addEventListener("DOMContentLoaded", () => {
  const selectElement = document.querySelector(".tom-select");

  const tomSelectSettings = {
    plugins: ["remove_button"],
  };

  if (selectElement !== null) {
    new TomSelect(".tom-select", tomSelectSettings);
  }

  setTimeout(() => {
    jsHelper.hide(".flash-messages");
  }, 5000);
});

document.addEventListener("htmx:afterSwap", () => {
  setTimeout(() => {
    jsHelper.hide(".flash-message");
  }, 5000);
});
